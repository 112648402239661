import React, { useEffect } from 'react';
import Resizer from "react-image-file-resizer";
import { MediaUpload } from '../Constant/api';

export default function ImageUpload(props) {
  const {
    ImageUrl,
    setImageUrl,
  } = props;
  const fileRefSingle = React.createRef();
  const [Loading, setLoading] = React.useState(false)

  const MediaUploadSingleCall = (file, i) => {
    if (!file) return;
    var fileInput = "";
    if (file) {
      fileInput = file.type;
    }
    try {
      // Resizer.imageFileResizer(
      //   file,
      //   1000,
      //   1000,
      //   "JPEG",
      //   40,
      //   0,
      //   (uri) => {
          // this.setState({ newImage: uri });
          console.log("2", file)

          MediaUpload(file)
            .then(res => res.json())
            .then(data => {
              setImageUrl(data.source_url)
              setTimeout(() => {
                setLoading(false)
              }, 1000);
            })
            .catch(err => {
              setLoading(false)
              console.log("error", err.message);
              console.log("error", err);
              console.log("error", err);
            })
            .finally(() => { })
    //     },
    //     "file",
    //     200,
    //     200
    //   );
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      {Loading &&
        <div className="spinner-border"></div>
      }
      <div className="">
        <div className="row">
          {ImageUrl !== "" ?
            <div className="my-2">
              <div className=" ">
                <button type="button" onClick={() => { fileRefSingle.current.click() }}
                  className="bg-transparent border-0 p-1 "
                >
                  <img src={`${ImageUrl}`} className={" border border-dark"}
                    height="100px"
                  />
                </button>
              </div>
              <button onClick={() => {
                setImageUrl("")
              }}
                className="bg-transparent border-0 p-1 "
              ><u>Clear</u></button>
            </div>
            :
            <div className="col-3">
              <button type="button"
                onClick={() => {
                  fileRefSingle.current.click()
                }}
                className="bg-transparent border-0 p-1 "
              >
                <div className="border height-width-150 border-dark m-1 p-4 font-size-12">
                  <i className="fa fa-camera font-size-18"></i>
                  <div>Add Image</div>
                </div>
              </button>
            </div>
          }
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <input type="file" accept="image/*" name="image" id="file" ref={fileRefSingle}
          onChange={(event) => {
            if (event.target.files.length !== 0) {
              setLoading(true)
              MediaUploadSingleCall(event.target.files[0])
            }
          }}
        />
      </div>


    </div>
  )
}